<template>
    <div class="mt-1 text-small" :class="textLabel">
        <span>There are required fields not completed:</span>
        <span
            v-for="(item, index) in camposPorLLenar(product)"
            :key="item.key"
        > {{ item.campo}}
            <span v-if="index != Object.keys(camposPorLLenar(product)).length - 1">,</span>
        </span>
    </div>
</template>

<script>
import {  mapState } from 'vuex'

import { camposRequeridosPorLLenar } from '@/helpers/helpers'

export default {
    props: {
        product: {
            type: Object,
            required: true,
        },       
    },
    computed:{
        ...mapState('appConfig',['layout']),
        textLabel(){
            return this.layout.skin === 'dark' ? 'color-dark-text': 'color-light-text'    
        }
    },
    methods:{
        camposPorLLenar(product) {
            return camposRequeridosPorLLenar(product) //helper
        },
    }    
}
</script>
<style scoped>
.color-dark-text {    
    color: #ff3d00;
    font-weight: bold;
}
.color-light-text {    
    color: red;
}
</style>