<template>
  <div class="checkout-options" v-if="cart.cliente.currency">        
    <b-card no-body class="border-primary" >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Total purchase
      </h5>
      <b-badge variant="success">
        {{cart.cliente.currency.code}}
      </b-badge>
      <!-- <small class="text-muted w-100">Choose a currency to see the conversion</small> -->
    </b-card-header>

    <b-card-body>
      <b-form-select id="divisaSelect" @change="calcularImportes" v-model="cart.cliente.currency" v-if="cart.totalBreakdown.total > 0">
          <option v-for="cur in currencies" :key="cur.id" :value="cur">{{cur.code}}</option>
      </b-form-select> 
      <div class="d-flex justify-content-between mt-1 mb-2">
        <div class="detail-title">
          <b>Exchange rate : </b><b-badge variant="light-secondary"> {{tc}} </b-badge><br>
          <b>Total currency : </b><b-badge variant="light-secondary"> ${{totalDivisa}} </b-badge>                                     
        </div>
        <div>
          <b>SubTotal:</b><b-badge variant="light-secondary"> ${{subTotal}} </b-badge> <br>
          <b>Total:</b> <b-badge variant="light-secondary">${{total}} </b-badge>                  
        </div>

      </div>            
      <b-button
        variant="primary"
        block
        @click="goToCheckout"
        :disabled="cart.items.length == 0"          
      > Checkout 
      </b-button>
    </b-card-body>
  </b-card>
  </div>   
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { toDecimal, toJson } from '@/helpers/helpers'
import { market } from '@/modules/shop/mixins/market'

export default {  
	mixins: [ market ],    
	computed:{ 
		...mapState('shop',['cart']),
		...mapState('start',['currencies']),
		...mapGetters('shop',['totalDiscountOnAllProducts','totalBreakdown']),
		
		subTotal(){      
			return toDecimal( parseFloat(this.totalDiscountOnAllProducts) + parseFloat(this.cart.totalBreakdown.total))           
		},	
    
		total(){
			return toDecimal(this.cart.totalBreakdown.total)          
		},
		tc(){
			const currValue = this.cart.cliente.currency ? this.cart.cliente.currency.value : 1 
			return  toDecimal(currValue)  
		},
		totalDivisa(){
			const currValue = this.cart.cliente.currency ? this.cart.cliente.currency.value : 1 
			const totalDivisa = parseFloat(this.cart.totalBreakdown.total) *  parseFloat(currValue)
			return toDecimal(totalDivisa) 
		}
	},
	methods:{
		...mapMutations('shop',['setCurrency']), 
		calcularImportes(){       
			const currValue = this.cart.cliente.currency ? this.cart.cliente.currency.value : 1
			const totalD = parseFloat(this.cart.totalBreakdown.total) * parseFloat(currValue)             
			this.divisaTotal = toDecimal(totalD)            
			this.setCurrency(this.cart.cliente.currency)
		}, 
		goToCheckout(){
			this.$router.push({name: 'payment', query: { ...this.getQueryParams() }})
		}  
	},
  watch: {
    totalBreakdown: {
      deep: true,
      handler() {        
        if( this.totalBreakdown === '0.00' ){          
          const divisa = toJson( this.currencies.find( currency => currency.code === 'USD' ) )              
          this.setCurrency( divisa )          
        }         
      },
    },
  },
}
</script>


<style lang="scss" scoped>

.card-img-top {
  width: 100%;
  height: 25vh;  
  object-fit: cover;
}
.ecommerce-application .grid-view .ecommerce-card .item-img {
    padding-top: 0rem;    
    min-height: 0rem;
    display: flex;
    align-items: center;
}

.detail-totalParcial{
color:crimson;
font-weight:bold;

}
</style>