<template>
    <b-card no-body style="max-width: 100%">      
        <b-row no-gutters>  
            <div class="col-xs-6 col-md-3 col-sm-12">     
                <b-card-img
                    style="height: 100%"
                    :src="imgUrl + product.coverImage"
                    @error="imgAlternativo"
                />
            </div>
            <div class="col-xs-6 col-md-6 col-sm-12">
                <b-card-body>	            
                    <div class="wrapper-product-info">                     
                        <div class="info-product" id="style-description">
                            <div class="force-overflow">
                                <div  class="item-name"><h5>
                                    <b>{{ product.name || "" }}</b></h5>
                                </div>
                                <span class="item-company">Category:</span><b-badge variant="success">{{ product.categoryName }}</b-badge>
                                <div v-if="queryParams.lang && queryParams.lang === 'en' " >
                                    <div v-html="product.detailEnglish.description"  class="mt-1 " v-if="product.detailEnglish"></div>
                                    <div v-html="product.detailSpanish.description"  class="mt-1 " v-else></div>

                                </div>
                                <div v-if="queryParams.lang && queryParams.lang === 'es' " >
                                    <div v-html="product.detailSpanish.description"  class="mt-1 " v-if="product.detailSpanish"></div>
                                    <div v-html="product.detailEnglish.description"  class="mt-1 " v-else></div>
                                </div>
                                <div v-if="!queryParams.lang" >
                                    <div v-html="product.detailEnglish.description"  class="mt-1 " v-if="product.detailEnglish"></div>
                                    <div v-html="product.detailSpanish.description"  class="mt-1 " v-else></div>
                                </div>
                            </div>
                        </div>   
                    </div>
                <div class="mt-1">
                <label for="textarea-default"> <b>Notes</b> </label>
                <b-form-textarea
                    v-model="product.clientnote"                
                    placeholder="Write a note"
                    rows="1"
                    style="height:50px;" 
                    @input="setDataProductItem(product)"
                />
                </div>
                <CamposRequeridosMsg :product="product" v-if="!product.requiredFieldsFilled" />

            </b-card-body>
            </div>
            <div class="col-xs-6 col-md-3 col-sm-12" style="padding: 1rem">
                <h4 class="text-center mt-2 mb-1"> <b>$ {{ subTotalProductoQty(product) }} USD </b></h4>

                <div class="  text-center mb-1">
                
                    <h5 class="">Qty</h5>
                    <div class="item-quantity">
                        <b-form-spinbutton
                            v-model="product.qty"
                            min="1"
                            size="sm"
                            class="ml-75"
                            inline
                            @change="changeQty($event, product)"
                        />
                    </div>
                    
                </div>

                <div v-if="product.operationdate">
                    <p class="check-p">Check In Date</p>
                    <span>{{product.operationdate}}</span>
                </div>

                <ValidationProvider rules="required" name="Operation date"  v-if="!product.operationdate">
                    <b-form-group slot-scope="{ valid, errors }" label="Check In Date">


                        <flat-pickr
                            v-model="product.operationdate"
                            class="form-control"
                            :config="product.blackoutDates"
                            :state="errors[0] ? false : (valid ? true : null)"
                            @input="setDateOperation(product)"
                        
                        />
                    </b-form-group>
                </ValidationProvider>

                <div class="mt-1">

                    <div v-if="queryParams.lang && queryParams.lang === 'en' " >
                        <div v-if="product.englishDates">
                            <p> <b>Event date:</b> {{ product.englishDates.startDate }}</p>
                            <p class="p-time"> <b>Event Start Time:</b> {{ product.startTimeFormatted }}</p>
                            <p class="p-time"> <b>Event End Time:</b> {{ product.endTimeFormatted }}</p>
                        </div>
                    </div>
                    <div v-if="queryParams.lang && queryParams.lang === 'es' " >
                        <div v-if="product.spanishDates">
                            <p> <b>Event date:</b> {{ product.spanishDates.startDate }}</p>
                            <p class="p-time"> <b>Event Start Time:</b> {{ product.startTimeFormatted }}</p>
                            <p class="p-time"> <b>Event End Time:</b> {{ product.endTimeFormatted }}</p>

                        </div>
                    </div>
                    <div v-if="!queryParams.lang" >
                        <div v-if="product.englishDates">
                            <p> <b>Event date:</b> {{ product.englishDates.startDate }}</p>
                        </div>
                        <div v-else>
                            <p> <b>Event date:</b> {{ product.spanishDates.startDate }}</p>
                        </div>
                        <p class="p-time"> <b>Event Start Time:</b> {{ product.startTimeFormatted }}</p>
                        <p class="p-time"> <b>Event End Time:</b> {{ product.endTimeFormatted }}</p>
                    </div>
                </div>
            </div>
        </b-row>
    </b-card> 
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"

import CamposRequeridosMsg from "@/modules/shop/components/orderSummary/CamposRequeridosMsg"
import flatPickr from 'vue-flatpickr-component'

export default {
    props: {
        product: {
            type: Object,
            required: true,
        },              
    },
    components: {
        //SFC
        flatPickr,
        CamposRequeridosMsg
    },
    data(){
        return {
            imgUrl: process.env.VUE_APP_IMG_SRC_API,
            imdDefault: require("@/assets/images/default.jpg"),
        }
    },
    computed:{
        ...mapState('auth',['queryParams','user']),    
    },
    methods: {    
        ...mapActions('products', ['fetchUnavailableDates']),
        ...mapMutations('shop',['setDataProductItem','setOperationDate','calculateTotal','setBlackoutDatesProduct']), 
        imgAlternativo(event) {
            event.target.src = this.imdDefault
        },
        setDateOperation(item){
            this.setOperationDate(item)
        }, 
        subTotalProductoQty(item) {
            const subTotal = ( Math.round( item.qty * (item.discountApplied > 0 ? item.salepriceWithDiscount : item.saleprice) * 100 ) / 100 ).toFixed(2)            
            return subTotal
        }, 
        async changeQty(qty) {      
    
            if (qty !== 0 ) {
                this.calculateTotal()
            }

            // http://192.168.42.77:8080/sales/order?token=82a577c4822b981b568b8b28bfb2e0cf4c9b936d&product=2898&detail=3787&type=events&lang=es
        },
  }
}
</script>

<style scoped>

.wrapper-product-info{
	margin: auto; 
}

.info-product{
	height: 250px;
	overflow-y: scroll;
}

.force-overflow {
	min-height: 250px;
}

#style-description::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-description::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}

#style-description::-webkit-scrollbar-thumb
{
	background-color: #333366;	
	background-image: -webkit-linear-gradient(45deg,rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%,rgba(255, 255, 255, .2) 50%,rgba(255, 255, 255, .2) 75%,transparent 75%,transparent)
}
.options-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.check-p{
    margin-block-end: 0;
    font-weight: bold;
}
.p-time{
    margin-block-end: 0;

}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>