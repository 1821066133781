<template>
    <b-card no-body style="max-width: 100%">      
      <b-row no-gutters>  
        <div class="col-xs-6 col-md-3 col-sm-12">     
            <b-card-img
                style="height: 100%"
                :src="imgUrl + product.coverImage"
                @error="imgAlternativo"
            />
        </div>
        <div class="col-xs-6 col-md-6 col-sm-12">
          <b-card-body>	            
            <div class="wrapper-product-info">                     
              <div class="info-product" id="style-description">
                <div class="force-overflow">
                  <div  class="item-name"><h5>
                    <b>{{ product.name || "" }}</b></h5></div>
                  <div  class="item-name"><h5>{{ product.detaildisplay || "" }}</h5> </div>
                  <span class="item-company">Category:</span><b-badge variant="success">{{ product.categoryName }}</b-badge>
                  <div v-html="product.description"  class="mt-1 "></div>
                </div>
              </div>   
            </div>
            <div class="mt-1">
              <label for="textarea-default"> <b>Notes</b> </label>
              <b-form-textarea
                v-model="product.clientnote"                
                placeholder="Write a note"
                rows="1"
                style="height:50px;" 
                @input="setDataProductItem(product)"
              />
            </div>
            <CamposRequeridosMsg :product="product" v-if="!product.requiredFieldsFilled" />

          </b-card-body>
        </div>
        <div class="col-xs-6 col-md-3 col-sm-12" style="padding: 1rem">
            <h4 class="text-center mt-2 mb-2"> <b>$ {{ subTotalProductoQty(product) }} USD </b></h4>

            <div class="  text-center mb-2">
               
				<h5 class="">Cantidad</h5>
				<div class="item-quantity">
					<b-form-spinbutton
						v-model="product.qty"
						min="1"
						size="sm"
						class="ml-75"
						inline
						@change="changeQty($event, product)"
					/>
				</div>
                
            </div>
          
          <ValidationProvider rules="required" name="Operation date" >
            <b-form-group slot-scope="{ valid, errors }" label="Check In Date">
              <flat-pickr
                v-model="product.operationdate"
                class="form-control"
                :config="product.blackoutDates"
                :state="errors[0] ? false : (valid ? true : null)"
                @input="setDateOperation(product)"
              />
              <!-- @input="setDateOperation(product)" -->
<!--  -->
            </b-form-group>
          </ValidationProvider>
        </div>
      </b-row>
    </b-card> 
</template>

<script>
import { mapActions, mapMutations } from "vuex"

import CamposRequeridosMsg from "@/modules/shop/components/orderSummary/CamposRequeridosMsg"
import flatPickr from 'vue-flatpickr-component'
import { showAlertMessage, currentDate } from '@/helpers/helpers'

export default {
    props: {
        product: {
            type: Object,
            required: true,
        },              
    },
    components: {
        //SFC
        flatPickr,
        CamposRequeridosMsg
    },
    data(){
        return {
            imgUrl: process.env.VUE_APP_IMG_SRC_API,
            imdDefault: require("@/assets/images/default.jpg"),
        }
    },
  
  methods: {    
    ...mapActions('products', ['fetchUnavailableDates']),
    ...mapMutations('shop',['setDataProductItem','setOperationDate','calculateTotal','setBlackoutDatesProduct']), 
    imgAlternativo(event) {
        event.target.src = this.imdDefault
    },
    setDateOperation(item){
        this.setOperationDate(item)
    }, 
    subTotalProductoQty(item) {
      const subTotal = ( Math.round( item.qty * (item.discountApplied > 0 ? item.salepriceWithDiscount : item.saleprice) * 100 ) / 100 ).toFixed(2)            
      return subTotal
    }, 
    async changeQty(qty, item) {      
   
      if (qty !== 0 ) {
       
        const text = `Vuelva a elegir una fecha disponible`;
        showAlertMessage("La cantidad ha cambiado", "InfoIcon", text, "warning", 4000, "bottom-right" )
        item.operationdate = null
        this.setDataProductItem(item) // si hay fecha de operación, se limpia         
        
		const unAvailableDates = await this.fetchUnavailableDates({idP: item.id, qty, fini: currentDate()})
        unAvailableDates.sort( (a,b) => { return new Date(a.date) - new Date(b.date) }) // ordeno las fechas
      	const blackoutDates = unAvailableDates.map( d => d.date)

        this.setBlackoutDatesProduct({uuid: item.uuid, blackoutDates})
        this.calculateTotal()
      }
    },
	// http://192.168.42.77:8080/sales/order?token=82a577c4822b981b568b8b28bfb2e0cf4c9b936d&product=3151&detail=5932&type=products&lang=es
  }
}
</script>

<style scoped>

.wrapper-product-info{
	margin: auto; 
}

.info-product{
	height: 250px;
	overflow-y: scroll;
}

.force-overflow {
	min-height: 250px;
}

#style-description::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-description::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}

#style-description::-webkit-scrollbar-thumb
{
	background-color: #333366;	
	background-image: -webkit-linear-gradient(45deg,rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%,rgba(255, 255, 255, .2) 50%,rgba(255, 255, 255, .2) 75%,transparent 75%,transparent)
}
.options-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>