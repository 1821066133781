import { mapState,  mapMutations, mapGetters } from 'vuex'
import { toJson,  removeNullValuesFromAnObject} from '@/helpers/helpers'
import { version } from '../../../../package'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

export const market = {
  data () {
    return { 
      params: this.$route.query,      
      codesAllowed: [32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57],      
    }
  }, 
  computed:{    
    ...mapState('products',['products','bestSellers']),    
    ...mapState('shop',['cart','orderCreated']),       
    ...mapState('auth',['queryParams','lang']), 
    ...mapGetters('shop',['isEmptyCart']),
    idItemDetail(){
      return this.$route.query.idItem ? this.$route.query.idItem : null
    },    
	dateFormatted(){
		if( this.queryParams ){
			const langLocale = this.queryParams.lang
			const langs = { en: 'en-US', es:'es-MX' }
			const defaultDateOptions = {
				day: 'numeric',
				weekday: 'long', // short/long
				month: 'short', // short/long
				year:'numeric'
			}
			const date = new Date( this.queryParams.date+'T00:00:00' )
			return  new Intl.DateTimeFormat(langs[langLocale], defaultDateOptions ).format( date ) // langs[langLocale] es langs.en o langs.es
		}
    },                     
  },
  methods: {                                   
    ...mapMutations('start',['setSelectedCategory','setSelectedDestination']),
    ...mapMutations('auth',['setDataQueryParams','setLangApplication']),   
    ...mapMutations('products',['setProductDetail']),
    ...mapMutations('shop',['setLanguageCustomer','addProductInCart','clearCart','calculateTotalsItemsCart','setOrderCreated']),    
 
    isANumber(string){
      return string ? !isNaN( string) : false
    },    
    givenDateIsGreaterThanToday(date){
      const today = new Date( currentDate() )
      const givenDate = new Date( date )
      return givenDate > today
    },
    setQueryStringInUrl(){     
      //del state se toma los queryStrings in se setea en la url   
      this.$router.replace({ query: {...this.queryParams} }).catch(() => {})
    },
    clearStorage(){
      const localVersion = localStorage.getItem('appVersion')
      if(!localVersion){
        console.log('clearStorage, remover vuex', version)
        localStorage.removeItem('vuex') //clear vuex
        localStorage.setItem('appVersion', version)
        window.location.reload(true)
      }
      if( localVersion ){
        if( localVersion !== version ){
          console.log('no son versiones iguales')
          localStorage.removeItem('vuex') //clear vuex
          localStorage.setItem('appVersion', version)
          window.location.reload(true)
        }
      }
    },   
    getQueryParams(){
      const hasQueryParams = Object.keys(this.$route.query).length > 0
      let queryParams = null                   
      // si viene parametros, tomar este valor y ponerlo en el state
      if( hasQueryParams ){                            
        queryParams = toJson( removeNullValuesFromAnObject( this.$route.query ))   
      }   
      return queryParams     
    },   
     
    overWriteAxiosHeaders () {
		const baseUrl = process.env.VUE_APP_BASE_URL_API
        //sobreescribo los datos en axios, una vez obtenido un token
        axios.defaults.headers.common['Authorization'] = 'Token '+ this.queryParams.token
        axios.defaults.baseURL = baseUrl
        Vue.use(VueAxios, axios)// end    
    },
  
    calculateBreakdownInCart(){
      if(this.cart.length){        
        this.calculateTotalsItemsCart()
      }
    },
    
    overWriteTokenAxios(){      
      const langLocale = this.$i18n.locale
      const tokens =  { en: process.env.VUE_APP_API_TOKEN_EN, es: process.env.VUE_APP_API_TOKEN_ES }
      const langsValid = ['en', 'es', ] // 'pt' para portuguese, idiomas validos a usar
      const isValidLang = langsValid.includes(langLocale)
      const codeLang = isValidLang ? langLocale : 'en'
      const tokenToUse = tokens[codeLang]
      //sobreescribo los datos en axios, una vez obtenido un token
      axios.defaults.headers.common['Authorization'] = 'Token '+ tokenToUse      
      Vue.use(VueAxios, axios)// end      
    }  
  },
  watch: {            
    idItemDetail: { // '$route.query.idItem'
      // immediate: true,
      handler(newVal ) {
        //this.setProductDetail(null) //si hay algo en detalle de producto lo limpio   
        //console.log('cambio')     
      }
    }
  },
 
}



