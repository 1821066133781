<template> 
	<div>
		<div v-if="!isLoadingData">
			<div v-if="user && itemInCart">
				<b-row>
					<b-col md="8" v-if="queryParams.type ==='products' ">
						<Product :product="itemInCart"/>
					</b-col>
					<b-col md="8" v-if="queryParams.type ==='events' ">
						<Event :product="itemInCart"/>
					</b-col>
					<b-col md="8" v-if="queryParams.type ==='experiences' ">
						<Experience :product="itemInCart" @re-quote="reQuote"/>
					</b-col>

					<b-col md="4">
						<CheckoutOptions/>
					</b-col>
				</b-row>
			</div>
		</div>
		
		<div class="text-center mt-5" v-else>
			<b-spinner label="Loading..." variant="success" /><br />
			<strong>Loading information</strong>
		</div>

	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import Product from '@/modules/shop/components/orderSummary/Product'
import Event from '@/modules/shop/components/orderSummary/Event'
import Experience from '@/modules/shop/components/orderSummary/Experience'
import CheckoutOptions from '@/modules/shop/components/orderSummary/CheckoutOptions'

import Ripple from 'vue-ripple-directive'
import { toJson,  currentDate } from '@/helpers/helpers'

import { pushImpressionsToDL } from '@/helpers/dataLayerHelpers'
import { market } from '@/modules/shop/mixins/market'


export default {
	mixins: [ market, dataLayer],    
	async created() {              
		this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)    
		await this.init()  
	},
	
	destroyed() {
		this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
	},
	directives: {
		Ripple,
	},
	components: {  
		Product, 
		Event,  
		Experience,
		CheckoutOptions,
	},   
	data(){
		return {
		params: this.$route.query,
		isLoadingData: false,  
		previousRouteName: null,          
	}
  },
  computed:{
	...mapState('start',['currencies']),
    ...mapState('products',['isloadingProducts', 'products','relatedProducts']),    
    ...mapState('shop',['cart']),       
    ...mapState('auth',['queryParams','user', 'isLogged']),    
	itemInCart(){
		return this.cart.items.length > 0 ? this.cart.items[0] : null
	}                            
  }, 
  methods: {                
    ...mapActions('start',['fetchCurrencies']),     
    ...mapActions('auth',['loginWeb']),     
    ...mapActions('products',['getProduct','getEvent', 'getExperience','fetchUnavailableDates']),                
    ...mapMutations('start',['setCurrencies']),
    ...mapMutations('auth',['setDataQueryParams', 'setUser', 'logout', 'setParamsPaxDate']),  
    ...mapMutations('products',['setProducts','setRelatedProducts']),
    ...mapMutations('shop',['setCurrency','addProductInCartInitial','setHotelCliente','setTipoCliente','clearCart']),
	async init(){
		this.setIsLoadingData(true)
		this.setDataQueryParams(null)
		this.setUser( null )
		this.clearStorage()
		this.clearCart()
		this.setHotelCliente( null )
        this.setCurrencies([]) //muto state currencies en start           

		const querParams = this.getQueryParams() // del mixin market
		// console.log( this.$router.history.length )

		if(!querParams){
			this.$router.push({name: 'not-found' })
		}

		if( querParams ){
			const { token } = querParams
			if( !token ){ // sin token en la url lo mando al not found
				this.$router.push({name: 'not-found' })
			}
			if( token ){
				this.setDataQueryParams(querParams)
				this.overWriteAxiosHeaders()
				await this.loginApp()
				await this.handlerCurrencies()
			}
			if( this.isLogged ){
		 		document.title = `${this.user.name} ${this.user.lastname}` 

				await this.handlerItemToCart()
				this.setIsLoadingData(false)
		 	}
		}
		this.setIsLoadingData(false)
	},
	async handlerItemToCart(){
		const { isValid, type } = this.dataTypeItem() 
				
		if( isValid ){
			//obtengo los datos del producto, evento, experiencia
			const item = await this.handlerGetItem() // podrá retornar un producto, evento o experencia

			if( !item ){
				this.$router.push({name: 'not-found' })
				return
			}
			// fechas no disponibles sí se trata de productos
			if( type === 'products' ){
				const unAvailableDates = await this.fetchUnavailableDates({idP: item.id, qty: 1, fini: currentDate()})
				unAvailableDates.sort( (a,b) => { return new Date(a.date) - new Date(b.date) }) // ordeno las fechas
					const blackoutDates = unAvailableDates.map( d => d.date)
				item.blackoutDates.disable = blackoutDates
				this.addProductInCartInitial(item)
			}

			if( type === 'events' ){
				this.addProductInCartInitial(item)
			}

			if( type === 'experiences' ){

				// item.operationdate = this.params.date 
				item.operationtime = '12:00'
				item.experience = item.experienceId
				item.experienceContract = item.experienceContractRateId // este es el experienceContract, detailId

				item.detail.forEach( rate  => {
					item.experienceType = rate.type 
					item.experiencecost = rate.cost 
					item.saleprice = rate.amount 
					item.qty = rate.num 
					
					item.total = rate.total 
					this.addProductInCartInitial(item)
				})

			}

			this.setTipoCliente(8)
			this.setHotelParams()
			pushImpressionsToDL()     

		}          
		if( !isValid ){
			this.$router.push({name: 'not-found' })
		}
	},
	async reQuote(){
		this.setIsLoadingData(true)
		await this.handlerItemToCart()
		this.setIsLoadingData(false)
	},
	setHotelParams(){
		if( this.queryParams.hotel ){
			if( !isNaN( this.queryParams.hotel ) && this.queryParams.hotel > 0 ){
				this.setHotelCliente( parseInt( this.queryParams.hotel ) )
			}
		}
	},
    async loginApp(){      
        const { ok, user } = await this.loginWeb( this.queryParams.token )        
        if(ok){
          this.setUser( user )
        }
        if(!ok){
          this.logout()
          this.setDataQueryParams(null)      
          this.$router.push({name: 'not-authorized' })
        }
    },
  
    async handlerCurrencies(){
      if( this.currencies.length == 0 ){
        const currencies = await this.fetchCurrencies()  
        this.setCurrencies(currencies) //muto state currencies en start           
        const divisa = toJson( this.currencies.find( currency => currency.code === 'USD' ) )    
        this.setCurrency( divisa )
      }
    },
	formatDate(fecha) {
		const dia = fecha.substr(0, 2)
		const mes = fecha.substr(3, 2)
		const anio = fecha.substr(6, 4)

		return `${anio}-${mes}-${dia}`
	},
	dataTypeItem(){
		let isValid = false
		const { type, product, } = this.queryParams
		const validTypes = ['products', 'events', 'experiences']
		isValid = validTypes.includes(type) && !isNaN( product ) && product > 0 // si el id es entero positivo
		return { isValid, type, id: product }
	},
	async handlerGetItem(){
		const { type, id } = this.dataTypeItem()

		if( type === 'products' ){
			const dataProduct = await this.getProduct({idProducto: id, idSecCanal: this.user?.sectionCanal?.id })
			return dataProduct
		}

		if( type === 'events' ){
			const dataProduct = await this.getEvent({idEvent: id, idSecCanal: this.user?.sectionCanal?.id })
			return dataProduct
		}
		// si es una experiencía validar que venga una fecha igual o superior a hoy, que venga adultos y niños
		if( type === 'experiences' ){
			const payload = this.payloadExperience()
			// me aseguro que si no viene fecha, niños, adultos poner los default en el state y actualizar los queryparams en la url	
            this.setParamsPaxDate({adults: payload.adults, children: payload.children, date: payload.dateRange})
            this.$router.replace({ query: {...this.queryParams} }).catch(() => {})   

			payload.idExperience = parseInt(id)
			const dataProduct = await this.getExperience(payload)
			if( dataProduct ){
				dataProduct.operationdate =  payload.dateRange 
			}
			return dataProduct
		}

	},
	payloadExperience(){
		const payload = { dateRange: currentDate(), adults: 1, children: 0, idSecCanal: this.user?.sectionCanal?.id } // valor por defecto
		if( this.queryParams ){
			const { date, adults, children } = this.queryParams

			if( date ){
				payload.dateRange = this.validDate(date) // que sea fecha valida y que sea igual o mayor a la fecha actual
			}

			if( adults ){
				// si adults es un número tomar ese valor como int, sino poner 1 como default
				payload.adults = !isNaN( adults ) && adults > 0 ? parseInt(adults) : 1
			}

			if( children ){
				// si children es un número tomar ese valor como int, sino poner 0 como default, cero es un valor valido para niños
				payload.children = !isNaN( children ) && adults > -1 ? parseInt(children) : 0
			}
			
		}
		return payload
	},
	validDate(date) {
		const fechaValida = Date.parse(date)
		
		// La fecha no es válida, por eso regreso el default que es la fecha actual
		if ( isNaN(fechaValida) || fechaValida < Date.now()) {
			return currentDate()
		}

		return date
	},
	isANumber(string){
      return string ? !isNaN( string) : false
    },
	setIsLoadingData( boolean ){
		this.isLoadingData = boolean
	},
  },  
}

</script>

<style lang="scss">

@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>



