<template>
    <b-card no-body style="max-width: 100%">      
        <b-row no-gutters>  
            <div class="col-md-3 col-sm-12">     
                <b-card-img
                    style="height: 100%"
                    :src="imgUrl + product.coverImageUrl"
                    @error="imgAlternativo"
                />
            </div>
            <div class="col-md-6 col-sm-12">
                <b-card-body>	            
                    <div class="wrapper-product-info">                     
                        <div class="info-product" id="style-description">
                            <div class="force-overflow">
                                <div  class="item-name"><h5>
                                    <b>{{ product.name || "" }}</b></h5>
                                </div>
                                <span class="item-company">Category:</span><b-badge variant="success">{{ product.categoryName }}</b-badge>
                                <div v-html="product.description"  class="mt-1 "></div>
                            </div>
                        </div>   
                    </div>
                <div class="mt-1">
                <label for="textarea-default"> <b>Notes</b> </label>
                <b-form-textarea
                    v-model="product.clientnote"                
                    placeholder="Write a note"
                    rows="1"
                    style="height:50px;" 
                    @input="setDataProductItem(product)"
                />
                </div>
                <CamposRequeridosMsg :product="product" v-if="!product.requiredFieldsFilled" />

            </b-card-body>
            </div>
            <div class="col-md-3 col-sm-12" style="padding: 1rem">
    
                <div class="summary"  v-if="!isReadOnly">

                    <div class="button-switch">
                        <b-button
                            variant="primary"
                            size="sm"
                            @click="switchOption"        
                        >
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 7L7 17M7 7L17 17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>


                        </b-button>
                    </div>

                    <div class="sidebar__item-form">
                        <label class="sidebar__item-form--label"> Date </label>
                        <div class="sidebar__item-form--input">
                        <flat-pickr              
                            class="form-control mb-1"
                            placeholder="When..."              
                            v-model="paramsSearch.date"
                            @input="setDateParams"    
                            :config="dateConfig"   
                        />
                        </div>
                    </div>
                    <div class="sidebar__item-form">
                        <div class="item__total-guests">                
                        <span>No. Persons </span>                                                
                        <button class="item__btn-guests" type="button" :disabled="true" > {{ totalGuestEditMode }} </button>
                        </div>  
                        <div class="guests-pax">                
                        <span  class="pax-type"> Adults </span>                  
                        <div class="pax-qty">
                            <button class="btn-qty" type="button" :disabled="paramsSearch.adults == 1"  @click="setPaxAdults('-')">
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 7.5H14" stroke="#34303E"/>
                                </svg>
                            </button>
                            <span> {{ paramsSearch.adults }} </span>
                            <button class="btn-qty" type="button" @click="setPaxAdults('+')" >
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5 1V14M1 7.5H14" stroke="#34303E"/>
                                </svg>
                            </button>
                        </div>
                        </div>      
                        <div class="guests-pax">                
                        <span  class="pax-type"> Children</span>                  
                        <div class="pax-qty">
                            <button class="btn-qty" type="button" :disabled="paramsSearch.children == 0" @click="setPaxChildren('-')">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 7.5H14" stroke="#34303E"/>
                                </svg>
                            </button>
                            <span> {{paramsSearch.children}} </span>
                            <button class="btn-qty" type="button"  @click="setPaxChildren('+')" >
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5 1V14M1 7.5H14" stroke="#34303E"/>
                                </svg>
                            </button>
                        </div>
                        </div>           
                    </div>
                    <div class="sidebar__item-form">
                        <div class="btn-search">
                      
                        <b-button variant="primary" @click="setNewParams"> 
                            Search
                        </b-button>
                        </div>                                                             
                    </div>

                </div>

                <div class="summary" v-if="isReadOnly">        

                    <div class="button-switch">
                        <b-button
                            variant="primary"
                            size="sm"
                            @click="switchOption"        
                        > 
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.87601 18.1156C2.92195 17.7021 2.94493 17.4954 3.00748 17.3022C3.06298 17.1307 3.1414 16.9676 3.24061 16.8171C3.35242 16.6475 3.49952 16.5005 3.7937 16.2063L17 3C18.1046 1.89543 19.8954 1.89543 21 3C22.1046 4.10457 22.1046 5.89543 21 7L7.7937 20.2063C7.49951 20.5005 7.35242 20.6475 7.18286 20.7594C7.03242 20.8586 6.86926 20.937 6.69782 20.9925C6.50457 21.055 6.29783 21.078 5.88434 21.124L2.49997 21.5L2.87601 18.1156Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        </b-button>
                    </div>

                    <div class="item-content">
                        <div class="date">
                            <b>Check In Date</b>
                            <span class="date__span">{{ dateFormatted }}</span>
                        </div>                    
                    </div> 

                    <div class="item-content">                
                        <div class="guests-input">
                            <!-- <label class="pax_label">No. Persons</label>                                       -->
                            <div class="total-guests">                
                                <span> Total guests </span>                                                
                                <button class="badge-guests" type="button" :disabled="true" > {{totalGuest}} </button>
                            </div>                    
                        </div>
                        <div class="pax-summary" v-if="!isAGroup">  
                            <div class="pax-summary__content" v-if="queryParams.adults > 0 && detailAdult">
                                <span> Adults </span>
                                <span>$ {{detailAdult.total.toFixed(2)}} USD</span>
                            </div>      
                            <div class="pax-summary__content" v-if="queryParams.children > 0 && detailChild">
                                <span> Children  </span>
                                <span>$ {{detailChild.total.toFixed(2)}} USD</span>
                            </div>             
                        </div>  
                        <div class="pax-summary" v-if="isAGroup">
                            <div class="pax-summary__content">
                                <span>  Group Price </span>
                                <span>${{product.total}} USD</span>
                            </div>  
                        </div>                                                                         
                    </div>  
                    
                    <div class="item-content-footer">
                        <div class="item-content-footer__total">
                            <span> Total </span>           
                            <span>$ {{cart.totalBreakdown.total.toFixed(2)}} USD </span>           
                        </div>
                    </div> 
                   
                </div>  
            </div>
        </b-row>
    </b-card> 
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"


import CamposRequeridosMsg from "@/modules/shop/components/orderSummary/CamposRequeridosMsg"
import flatPickr from 'vue-flatpickr-component'
import { market } from '@/modules/shop/mixins/market'
import { currentDate, toJson } from '@/helpers/helpers'


export default {
	mixins: [ market ],    

    props: {
        product: {
            type: Object,
            required: true,
        },              
    },
    mounted(){
        this.setInitialParams()
    },
    components: {
        //SFC
        flatPickr,
        CamposRequeridosMsg
    },
    data(){
        return {
            imgUrl: process.env.VUE_APP_IMGEXP_SRC_API,
            imdDefault: require("@/assets/images/default.jpg"),
            isReadOnly: true, 
            paramsSearch:{
                adults: 1,
                children: 0,
                date: null,
            }  
        }
    },
    computed:{
        ...mapState('auth',['queryParams','user']), 
        ...mapState('shop',['cart', 'totalBreakdown']), 

        isAGroup(){
            const adultDetail = this.cart.items.find( item => item.detailSelected.typeDisplay === 'Group' )
            return adultDetail ? true : false
        } ,
        totalGuest()  {
            let total = 0
            const adults = this.queryParams.adults ? parseFloat(this.queryParams.adults) : 0
            const childs = this.queryParams.children ? parseFloat(this.queryParams.children) : 0
            total =  adults + childs
            return total
        },
        totalGuestEditMode(){
            let total = 0
            total = parseFloat(this.paramsSearch?.adults) + parseFloat(this.paramsSearch?.children)
            return total
        },
        detailAdult(){
            const itemCart = this.cart.items.length > 0 ? this.cart.items[0] : null
            let adult = itemCart.detail.find( detail => detail.typeDisplay === 'Adults') || null
            return adult
        },
        detailChild(){
            const itemCart = this.cart.items.length > 0 ? this.cart.items[0] : null
            let children = itemCart.detail.find( detail => detail.typeDisplay === 'Children') || null
            return children
        }, 
        totalItem(){
            const itemCart = this.cart.items.length > 0 ? this.cart.items[0] : null
            let children = itemCart.detail.reduce((acc, v) => acc + v.total, 0) || '0.00'
            return children
        }, 
        dateConfig(){
            const locale =  this.queryParams?.lang !== 'en' ? require(`flatpickr/dist/l10n/es.js`).default['es'] : 'en'
           
            return {
                // mode: "range",
                minDate: currentDate(),
                maxDate: '',        
                altInput: true,
                altFormat: "l, F j, Y",
                locale,
                // dateFormat: "Y-m-d",
            }
        }
        // grupo
        // http://192.168.43.3:8080/sales/order?token=6f292a4810d10da360ab264cf6e6d36b7b4f8ded&product=37&detail=5932&type=experiences&lang=es&date=2023-09-18&adults=2&children=1
        // http://192.168.43.24:8080/sales/order?token=6f292a4810d10da360ab264cf6e6d36b7b4f8ded&product=37&detail=5932&type=experiences&lang=es&date=2023-09-18&adults=2&children=1
        // niños y adultos        
        // http://192.168.43.29:8080/sales/order?token=6f292a4810d10da360ab264cf6e6d36b7b4f8ded&product=1007&detail=5932&type=experiences&lang=es&date=2023-09-26&adults=2&children=1
    },
    methods: {    
        ...mapActions('products', ['fetchUnavailableDates']),
        ...mapMutations('shop',['setDataProductItem','setOperationDate','calculateTotal','clearCart']), 
        ...mapMutations('auth',['setParamsPaxDate']), 

        setInitialParams(){
            if( this.queryParams ){
                const { date, adults, children } = this.queryParams
                this.paramsSearch.adults = adults ? adults : 1
                this.paramsSearch.children = children ? children : 0
                this.paramsSearch.date = date ? date : currentDate()
            }
        },
        imgAlternativo(event) {
            event.target.src = this.imdDefault
        },
        setDateOperation(item){
            this.setOperationDate(item)
        }, 
        subTotalProductoQty(item) {
            const subTotal = ( Math.round( item.qty * (item.discountApplied > 0 ? item.salepriceWithDiscount : item.saleprice) * 100 ) / 100 ).toFixed(2)            
            return subTotal
        }, 
        async changeQty(qty) {      
    
            if (qty !== 0 ) {
                this.calculateTotal()
            }
        },
        switchOption(){
            this.isReadOnly = !this.isReadOnly
        },
        setDateParams( date ){
            this.paramsSearch.date = date
        },
        setPaxAdults( operator ){
            this.paramsSearch.adults = operator === '-' ? parseFloat( this.paramsSearch.adults ) - 1 : parseFloat( this.paramsSearch.adults) + 1

        },
        setPaxChildren( operator ){
            this.paramsSearch.children = operator === '-' ? parseFloat( this.paramsSearch.children ) - 1 : parseFloat( this.paramsSearch.children) + 1
        },
        setNewParams(){
            const data = toJson( this.paramsSearch )
            this.setParamsPaxDate(data)
            this.$router.replace({ query: {...this.queryParams} }).catch(() => {})   
		    this.clearCart()
            this.$emit('re-quote')
        }
    }
}
</script>

<style lang="scss" scoped>

.wrapper-product-info{
	margin: auto; 
}

.info-product{
	height: 250px;
	overflow-y: scroll;
}

.force-overflow {
	min-height: 250px;
}

#style-description::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-description::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}

#style-description::-webkit-scrollbar-thumb
{
	background-color: #333366;	
	background-image: -webkit-linear-gradient(45deg,rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%,rgba(255, 255, 255, .2) 50%,rgba(255, 255, 255, .2) 75%,transparent 75%,transparent)
}


.date{
  display: flex;
  flex-direction: column;
  color: #000000;
}
.date__span{
    margin-top: 1rem;  
    margin-bottom: 1rem;
}
.summary{  
  display: flex;
  flex-direction: column;    
  position: relative;
  height: auto;
  background-color: rgb(255, 255, 255);
  border-radius: .5rem;  
  margin: 0 auto;  
  
  padding: 1rem 1rem;
}

.item-content{
  border-bottom: 1px solid #333366;  
  padding: .2rem;
  margin-bottom: 1rem;
}
.item-content-footer{
    display: flex;
    flex-direction: column;
    margin-block-start: .5rem;
    margin-block-end: 1.5rem;
}
.item-content-footer__total{
    display: flex;
    justify-content: space-between;
    margin-block-end: .5rem;
    font-weight: 600;
    color: #000000;
    font-size: 1.3rem;
}


.guests-input{
    display: flex;
    justify-content: space-between;
    align-items: center;    
    padding-bottom: .5rem;
    margin-block-end: 1rem;
    font-weight: bold;
    color: #000000;
}

.total-guests{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: .5rem;
}

.badge-guests {
    width: 30px;
    height: 30px;        
    font-size: .8rem;
    background-color: #333366;    
    color: rgb(255, 255, 255);
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    border: 0;    
    cursor: unset;
}

.pax-summary{
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.pax-summary__content{
    display: flex;
    justify-content: space-between;
    margin-bottom: .7rem;
}


.item-content{
  border-bottom: 1px solid #333366;  
  padding: .2rem;
  margin-bottom: 1rem;
}
.item-content-footer{
    display: flex;
    flex-direction: column;
    margin-block-start: .5rem;
    margin-block-end: 1.5rem;
}
.item-content-footer__total{
    display: flex;
    justify-content: space-between;
    margin-block-end: .5rem;
    font-weight: 600;
    color: #000000;
    font-size: 1.3rem;
}



.guests-input{
    display: flex;
    justify-content: space-between;
    align-items: center;    
    padding-bottom: .5rem;
    margin-block-end: 1rem;
    font-weight: bold;
    color: #000000;
}

.total-guests{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: .5rem;
}

.badge-guests {
    width: 30px;
    height: 30px;        
    font-size: .8rem;
    background-color: #333366;    
    color: rgb(255, 255, 255);
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    border: 0;    
    cursor: unset;
}

.pax-summary{
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.pax-summary__content{
    display: flex;
    justify-content: space-between;
    margin-bottom: .7rem;
}

.button-switch {
    display: flex;
    justify-content: flex-end;
}

  .sidebar__item-form{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0 0 1rem 0;
  }

  .sidebar__item-form--label{
    font-size: 1.1rem;
    color: #1D1929;  
    font-weight: 600;
    font-family: Century Gothic, Regular;
  }

  .sidebar__item-form--label:first-of-type{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .sidebar__item-form--input{ 
    display: flex;
    justify-content: space-between;    
    align-items: center;
  }

 
  .item__total-guests{
    display: flex;
    // justify-content: space-around;
    align-items: center;
    gap: .5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.item__total-guests span{
  color: #1D1929;     
  font-weight: 600;
}

.guests-pax{
    display: flex;
    justify-content: space-between;
    /* outline: 1px solid yellow; */
    margin-bottom: 1rem;
    font-weight: 600;
}
.pax-type{    
    font-weight: 500;
}
.pax-qty{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    min-width: 120px; 
}


.btn-qty {
  height:35px;
  width:35px;
  background-color: rgba(14, 12, 12, 0.1);   
  border-radius: 50%;
  border: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item__btn-guests {
    width: 30px;
    height: 30px;        
    font-size: .8rem;
    background-color: #333366 ;    
    color: rgb(255, 255, 255);
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    border: 0;    
    cursor: unset;
}



.btn-search {
  margin-left: auto; //en dispositivos moviles uso flex para alinear elementos en columnas, el btn lo alineo a la derecha
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>